














































































import { Component, Vue } from "vue-property-decorator";

import Collapse from "@/components/menu/Collapse.vue";

const trim = (str: string) => (str[0] === "#" ? str.substr(1) : str);

@Component({
  components: {
    Collapse,
  },
})
export default class Thanks extends Vue {
  hash: string = trim(this.$route.hash.toLowerCase());
}
